import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";
import CockpitSettingsWrapper from "./CockpitSettingsWrapper";
import "./styles/imageAndText.css";
import { Link } from "react-router-dom";
const ReactMarkdown = require("react-markdown/with-html");

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

class Content extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="imageAndTextContainer"
        style={{
          backgroundColor: this.props.bgColor,
          position: "relative"
        }}
      >
        <CockpitSettingsWrapper data={this.props.data}>
          <Fade cascade>
            <div className="imageAndText">
              <div
                className="imageAndTextElem thegrid "
                style={
                  this.props.data.settings.reverse
                    ? { flexDirection: "row-reverse" }
                    : { flexDirection: "row" }
                }
              >
                <div className="thecell x60 xs-x100 ">
                  <ConditionalWrapper
                    condition={
                      this.props.data.settings.image_url &&
                      this.props.data.settings.image_url.indexOf("/") === 0
                    }
                    wrapper={children => (
                      <Link
                        to={
                          !this.props.locale
                            ? this.props.data.settings.image_url
                            : `/${this.props.locale}${
                                this.props.data.settings.image_url
                              }`
                        }
                        title={this.props.data.settings.image.title}
                      >
                        {children}
                      </Link>
                    )}
                  >
                    <ConditionalWrapper
                      condition={
                        this.props.data.settings.image_url &&
                        this.props.data.settings.image_url.indexOf("/") !== 0
                      }
                      wrapper={children => (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={this.props.data.settings.image_url}
                          title={this.props.data.settings.image.title}
                        >
                          {children}
                        </a>
                      )}
                    >
                      <img
                        src={`https://scorpiomusic.fr/cms/api/cockpit/image?token=189f6f339371195fcccbd85236fdee&src=${
                          this.props.data.settings.image._id
                        }&w=640&h=480&q=50&o=true`}
                        alt={this.props.data.settings.image.title}
                      />
                    </ConditionalWrapper>
                  </ConditionalWrapper>
                </div>

                <div
                  className="thecell x40 xs-x100 padding text"
                  style={
                    this.props.data.settings.reverse
                      ? {
                          padding: "1rem",
                          display: "flex",
                          justifyContent: "flex-end",
                          color: this.props.textColor
                        }
                      : {
                          display: "flex",
                          flexDirection: "row",
                          padding: "1rem",
                          color: this.props.textColor
                        }
                  }
                >
                  <div>
                    <ReactMarkdown
                      source={this.props.data.settings.text}
                      escapeHtml={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </CockpitSettingsWrapper>
      </div>
    );
  }
}

export default Content;
