import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";
import "./styles/title.css";

class Title extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var width = "1024px";

    if (this.props.data.settings.width === "large") {
      width = "1280px";
    }
    if (this.props.data.settings.width === "medium") {
      width = "1024px";
    }
    if (this.props.data.settings.width === "small") {
      width = "640px";
    }

    return (
      <div
        className="titleContainer"
        style={{
          backgroundColor: this.props.bgColor
        }}
      >
        <div
          style={{
            maxWidth: width,
            margin: "0 auto"
          }}
        >
          <h1
            className={`title ${
              this.props.data.settings.offset ? "offset" : null
            }`}
            style={{
              color: this.props.titleColor,
              textAlign: this.props.data.settings.title_align
            }}
            dangerouslySetInnerHTML={{
              __html: this.props.data.settings.title
            }}
          />
        </div>
      </div>
    );
  }
}

export default Title;
