import React, { PureComponent } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import EmailIcon from "@material-ui/icons/Email";
import BookIcon from "@material-ui/icons/Book";
import CallIcon from "@material-ui/icons/Call";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import "./styles/bottomNav.css";

class BottomNav extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <BottomNavigation
        showLabels
        className="bottomNav"
        style={{
          backgroundColor: this.props.data.settings.bg_color
        }}
      >
        {this.props.data.settings.elems.map((elem, i) => {
          var icon = null;
          if (elem.value.icon === "mail") {
            icon = <EmailIcon />;
          }
          if (elem.value.icon === "location") {
            icon = <LocationOnIcon />;
          }
          if (elem.value.icon === "telephone") {
            icon = <CallIcon />;
          }
          if (elem.value.icon === "book") {
            icon = <BookIcon />;
          }
          return (
            <BottomNavigationAction
              key={i}
              component="a"
              href={elem.value.url}
              label={elem.value.label}
              title={elem.value.label}
              icon={icon}
              style={{
                color: this.props.data.settings.text_color
              }}
              target="_blank"
              rel="noopener noreferrer"
            />
          );
        })}
      </BottomNavigation>
    );
  }
}

export default BottomNav;
