import React, { PureComponent } from "react";

import { Link } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import "./styles/articlesFeed.css";
class ArticlesFeed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    const response = await fetch(
      `https://scorpiomusic.fr/cms/api/collections/get/blog`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "189f6f339371195fcccbd85236fdee"
        },
        body: JSON.stringify({
          filter: { published: true },
          lang: this.props.locale,
          sort: { date: -1 },
          limit: this.props.limit ? this.props.limit : 4
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();
    this.setState({
      fetching: false
    });
  }

  render() {
    return this.state.data.total < 0 ? (
      <div className="articlesFeed">
        <div
          className="articlesFeedContainer"
          style={{
            backgroundColor: this.props.bgColor,
            position: "relative"
          }}
        >
          <div className="articlesFeedContainerInner">
            <h3 style={{ textAlign: "center", fontSize: "1rem" }}>
              {this.props.data && this.props.data.settings.title
                ? this.props.data.settings.title
                : null}
            </h3>
            {!this.state.fetching
              ? this.state.data.entries.map((article, i) => {
                  const splittedDate = article.date.split("-");

                  return (
                    <Card
                      key={i}
                      className={
                        i === 0 ? "articleFeedCard first" : "articleFeedCard"
                      }
                    >
                      <Link
                        className="bigLink"
                        title={article.title}
                        to={
                          !this.props.locale
                            ? `/news/${article.url_handle}`
                            : `/${this.props.locale}/news/${article.url_handle}`
                        }
                      >
                        <CardActionArea>
                          {article.image ? (
                            <CardMedia
                              image={`https://scorpiomusic.fr/cms/api/cockpit/image?token=189f6f339371195fcccbd85236fdee&src=${
                                article.image._id
                              }&w=600&h=600&q=80&o=true`}
                              title={article.image.title}
                              style={{ height: "160px" }}
                            />
                          ) : null}
                          <CardContent>
                            <p
                              style={{
                                margin: 0,
                                padding: "0 0 0.5rem 0",
                                textTransform: "uppercase"
                              }}
                            >
                              <strong>{article.title}</strong>
                            </p>
                            <p
                              style={{
                                margin: 0,
                                padding: "0 0 0.5rem 0"
                              }}
                            >
                              <i>
                                {" "}
                                {splittedDate[2]}-{splittedDate[1]}-
                                {splittedDate[0]}
                              </i>
                            </p>
                          </CardContent>
                        </CardActionArea>
                      </Link>
                      <CardActions
                        className="articleFeedCardActions"
                        style={{ display: "none" }}
                      >
                        <Button
                          size="small"
                          component={Link}
                          color="primary"
                          variant="contained"
                          to={
                            !this.props.locale
                              ? `/news/${article.url_handle}`
                              : `/${this.props.locale}/news/${
                                  article.url_handle
                                }`
                          }
                        >
                          Learn More
                        </Button>
                      </CardActions>
                    </Card>
                  );
                })
              : null}
            {this.props.data &&
            this.props.data.settings &&
            this.props.data.settings.cta ? (
              <div style={{ paddingTop: "2rem" }}>
                <Button
                  style={{
                    backgroundColor: this.props.textColor
                  }}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={
                    !this.props.locale ? `/news` : `/${this.props.locale}/news`
                  }
                >
                  {this.props.data.settings.cta}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default ArticlesFeed;
