import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import CockpitSettingsWrapper from "./CockpitSettingsWrapper";

import "./styles/iconLinks.css";
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

class IconLinks extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        className="iconLinks"
        style={{
          backgroundColor: this.props.bgColor,
          position: "relative"
        }}
      >
        <CockpitSettingsWrapper data={this.props.data}>
          <div>
            {this.props.data.settings.iconlinks.map((iconlink, i) => {
              return iconlink.value.image ? (
                <ConditionalWrapper
                  key={i}
                  condition={iconlink.field.name === "fileset"}
                  wrapper={children => (
                    <a
                      className="iconLink"
                      href={`https://bad-demo.badbyte.ch/${
                        iconlink.value.file
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={iconlink.value.label}
                    >
                      {children}
                    </a>
                  )}
                >
                  <ConditionalWrapper
                    key={i}
                    condition={
                      iconlink.field.name === "linkset" &&
                      iconlink.value.url.indexOf("/") !== 0
                    }
                    wrapper={children => (
                      <a
                        className="iconLink"
                        href={iconlink.value.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={iconlink.value.label}
                      >
                        {children}
                      </a>
                    )}
                  >
                    <ConditionalWrapper
                      key={i}
                      condition={
                        iconlink.field.name === "linkset" &&
                        iconlink.value.url.indexOf("/") === 0
                      }
                      wrapper={children => (
                        <Link
                          className="iconLink"
                          to={
                            !this.props.locale
                              ? iconlink.value.url
                              : `/${this.props.locale}${iconlink.value.url}`
                          }
                          title={iconlink.value.label}
                        >
                          {children}
                        </Link>
                      )}
                    >
                      <Fade bottom>
                        <img
                          src={`https://scorpiomusic.fr/cms/storage/uploads${
                            iconlink.value.image.path
                          }`}
                          alt={
                            iconlink.value.label ? iconlink.value.label : null
                          }
                        />
                        {iconlink.value.label ? (
                          <span
                            style={{
                              color: this.props.textColor
                            }}
                          >
                            {iconlink.value.label}
                          </span>
                        ) : null}
                      </Fade>
                    </ConditionalWrapper>
                  </ConditionalWrapper>
                </ConditionalWrapper>
              ) : null;
            })}
          </div>
        </CockpitSettingsWrapper>
      </div>
    );
  }
}

export default IconLinks;
