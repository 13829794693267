import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import TagManager from "react-gtm-module";
import CockpitComponentsDispatcher from "../CockpitComponentsDispatcher";

class Page extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    const response = await fetch(
      `https://scorpiomusic.fr/cms/api/collections/get/pages`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "189f6f339371195fcccbd85236fdee"
        },
        body: JSON.stringify({
          populate: 6,
          filter: { url_handle: this.props.match.params.id },
          lang: this.props.match.params.locale
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();
    sessionStorage.setItem(
      `page_${this.props.match.params.id}_${this.props.match.params.locale}`,
      JSON.stringify(jsonData.entries[0])
    );

    this.setState({
      data: jsonData.entries[0]
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });
    const cachedPage = sessionStorage.getItem(
      `page_${this.props.match.params.id}_${this.props.match.params.locale}`
    );
    if (cachedPage) {
      this.setState({
        data: JSON.parse(cachedPage),
        fetching: false
      });
    }

    await this.fetchData();

    this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      window.scroll(0, 0);
      await this.setState({
        fetching: true
      });
      const cachedPage = sessionStorage.getItem(
        `page_${this.props.match.params.id}_${this.props.match.params.locale}`
      );
      if (cachedPage) {
        this.setState({
          data: JSON.parse(cachedPage),
          fetching: false
        });
      }
      await this.fetchData();

      this.setState({
        fetching: false
      });
    }
  }

  render() {
    if (!this.state.fetching) {
      const tagManagerArgs = {
        dataLayer: {
          page: this.state.data.title
        },
        dataLayerName: "PageDataLayer"
      };
      TagManager.dataLayer(tagManagerArgs);
    }
    return (
      <div className="page">
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          {!this.state.fetching ? (
            <Helmet>
              <html
                lang={
                  this.props.match.params.locale
                    ? this.props.match.params.locale
                    : "en"
                }
              />
              <meta charSet="utf-8" />
              <title>
                {this.state.data.title ? this.state.data.title : null} - SCORPIO
                MUSIC
              </title>
              <meta name="description" content={this.state.data.meta} />
            </Helmet>
          ) : null}

          {!this.state.fetching ? (
            <CockpitComponentsDispatcher
              data={this.state.data}
              locale={this.props.match.params.locale}
            />
          ) : null}
        </Layout>
      </div>
    );
  }
}

export default Page;
