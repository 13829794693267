import React, { PureComponent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CockpitSettingsWrapper from "./CockpitSettingsWrapper";
import styled from "styled-components";
import "./styles/form.css";

var mailObj = {};
class Form extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleField = name => event => {
    event.preventDefault();
    mailObj[name] = event.target.value;
  };
  handleChange = name => event => {
    mailObj[name] = event.target.checked;
  };
  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
  }

  render() {
    const StyledTextField = styled(TextField)`
      label {
        color: ${this.props.textColor};
        opacity: 1;
      }
      label.focused {
        color: ${this.props.textColor};
        opacity: 1;
      }
      .MuiInputBase-input {
        color: ${this.props.textColor};
      }
      .MuiOutlinedInput-root {
        fieldset {
          border-color: ${this.props.textColor};
          opacity: 0.6;
          color: ${this.props.textColor};
        }
        &:hover fieldset {
          border-color: ${this.props.textColor};
          opacity: 1;
        }
        &.Mui-focused fieldset {
          color: ${this.props.textColor};
          opacity: 1;
        }
      }
    `;

    return (
      <div
        style={{
          backgroundColor: this.props.bgColor,
          color: this.props.textColor,
          position: "relative"
        }}
      >
        <CockpitSettingsWrapper data={this.props.data}>
          <form
            className="form"
            action={this.props.data.settings.action}
            method="post"
            name="mc-embedded-subscribe-form"
            noValidate
          >
            <h2
              style={{
                color: this.props.titleColor
              }}
            >
              {this.props.data.settings.title}
            </h2>
            {this.props.data.settings.form.map((item, i) => {
              return (
                <>
                  {item.value.fieldtype === "text" ||
                  item.value.fieldtype === "" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <StyledTextField
                        style={{
                          color: this.props.textColor
                        }}
                        className="textfield text"
                        required={item.value.required}
                        id={item.value.id}
                        name={item.value.name}
                        label={item.value.label}
                        onChange={this.handleField(item.value.name)}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                  ) : null}

                  {item.value.fieldtype === "number" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <StyledTextField
                        style={{
                          color: this.props.textColor
                        }}
                        className="textfield number"
                        type="number"
                        required={item.value.required}
                        id={item.value.id}
                        name={item.value.name}
                        label={item.value.label}
                        onChange={this.handleField(item.value.name)}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                  ) : null}

                  {item.value.fieldtype === "email" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <StyledTextField
                        style={{
                          color: this.props.textColor
                        }}
                        className="textfield email"
                        type="email"
                        required={item.value.required}
                        id={item.value.id}
                        name={item.value.name}
                        label={item.value.label}
                        onChange={this.handleField(item.value.name)}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                  ) : null}

                  {item.value.fieldtype === "date" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <StyledTextField
                        style={{
                          color: this.props.textColor
                        }}
                        className="textfield date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        required={item.value.required}
                        id={item.value.id}
                        name={item.value.name}
                        label={item.value.label}
                        onChange={this.handleField(item.value.name)}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                  ) : null}

                  {item.value.fieldtype === "textarea" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <StyledTextField
                        style={{
                          color: this.props.textColor
                        }}
                        className="textfield textarea"
                        required={item.value.required}
                        id={item.value.id}
                        name={item.value.name}
                        label={item.value.label}
                        onChange={this.handleField(item.value.name)}
                        margin="normal"
                        variant="outlined"
                        multiline
                      />
                    </div>
                  ) : null}

                  {item.value.fieldtype === "checkbox" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <input
                        required={item.value.required}
                        onChange={this.handleChange(item.value.name)}
                        type="checkbox"
                        id={item.value.id}
                        name={item.value.name}
                        className="checkbox"
                      />
                      <label
                        style={{
                          paddingLeft: "1rem"
                        }}
                        htmlFor={item.value.id}
                      >
                        {item.value.label}
                      </label>
                    </div>
                  ) : null}

                  {item.value.fieldtype === "select" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <StyledTextField
                        style={{
                          color: this.props.textColor
                        }}
                        className="textfield select"
                        select
                        required={item.value.required}
                        id={item.value.id}
                        name={item.value.name}
                        label={item.value.label}
                        onChange={this.handleField(item.value.name)}
                        margin="normal"
                        variant="outlined"
                        color="inherit"
                        SelectProps={{
                          native: true
                        }}
                      >
                        <option disabled selected>
                          ...
                        </option>
                        {item.value.options.split(",").map((option, j) => {
                          return <option key={option}>{option}</option>;
                        })}
                      </StyledTextField>
                    </div>
                  ) : null}
                </>
              );
            })}
            <div className="ctaContainer">
              <Button
                style={{
                  color: this.props.bgColor,
                  backgroundColor: this.props.textColor
                }}
                variant="contained"
                type="submit"
              >
                {this.props.data.settings.cta}
              </Button>
            </div>
          </form>
        </CockpitSettingsWrapper>
      </div>
    );
  }
}

export default Form;
