import React, { PureComponent } from "react";
import "./styles/youtubeFeed.css";
class YoutubeFeed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  async fetchData() {
    // var locale = this.props.match.params.locale;
    // var filter;
    // if (locale === undefined) {
    //   filter = "title_slug";
    // } else {
    //   filter = `title_${locale}_slug`;
    // }

    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${
        this.props.data.settings.plistID
      }&maxResults=${this.props.data.settings.n}&key=${
        this.props.data.settings.api
      }`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
        // body: JSON.stringify({
        //   part: "snippet"
        // })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();

    this.setState({
      fetching: false
    });
  }

  render() {
    return !this.state.fething && this.state.data.items ? (
      <div
        className="youtubeFeedContainer"
        style={{
          backgroundColor: this.props.bgColor,
          position: "relative",
          textAlign: "center"
        }}
      >
        <div className="youtubeFeedContainerInner">
          <h3 style={{ textAlign: "center", fontSize: "1rem" }}>
            {this.props.data && this.props.data.settings.title
              ? this.props.data.settings.title
              : null}
          </h3>
          {this.state.data.items.map((item, i) => {
            return (
              <div
                key={i}
                className="videoFeedContainer"
                style={{
                  width: "100%",
                  maxWidth: "587px",
                  padding: "1rem",
                  display: "inline-block",
                  boxSizing: "border-box"
                }}
              >
                <iframe
                  src={`https://www.youtube.com/embed/${
                    item.snippet.resourceId.videoId
                  }?showinfo=0&controls=0`}
                  title="video"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen={true}
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "inline-block"
                  }}
                  frameBorder="0"
                />
              </div>
            );
          })}
        </div>
      </div>
    ) : null;
  }
}

export default YoutubeFeed;
