import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import TagManager from "react-gtm-module";
import CockpitComponentsDispatcher from "../CockpitComponentsDispatcher";

class PrivacyPolicy extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    const response = await fetch(
      `https://scorpiomusic.fr/cms/api/singletons/get/privacypolicy`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "189f6f339371195fcccbd85236fdee"
        },
        body: JSON.stringify({
          lang: this.props.match.params.locale
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();
    sessionStorage.setItem(
      `privacypolicy_${this.props.match.params.id}_${
        this.props.match.params.locale
      }`,
      JSON.stringify(jsonData)
    );

    this.setState({
      data: jsonData
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });
    const cachedData = sessionStorage.getItem(
      `privacypolicy_${this.props.match.params.id}_${
        this.props.match.params.locale
      }`
    );
    if (cachedData) {
      this.setState({
        data: JSON.parse(cachedData),
        fetching: false
      });
    }
    await this.fetchData();

    await this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      await this.setState({
        fetching: true
      });
      const cachedData = sessionStorage.getItem(
        `privacypolicy_${this.props.match.params.id}_${
          this.props.match.params.locale
        }`
      );
      if (cachedData) {
        this.setState({
          data: JSON.parse(cachedData),
          fetching: false
        });
      }
      await this.fetchData();

      await this.setState({
        fetching: false
      });
    }
  }

  render() {
    if (!this.state.fetching) {
      const tagManagerArgs = {
        dataLayer: {
          page: "Privacy Policy"
        },
        dataLayerName: "PageDataLayer"
      };
      TagManager.dataLayer(tagManagerArgs);
    }
    return (
      <div className="privacyPolicy">
        {!this.state.fetching ? (
          <Helmet>
            <html
              lang={
                this.props.match.params.locale
                  ? this.props.match.params.locale
                  : "en"
              }
            />
            <meta charSet="utf-8" />
            <title>{this.state.data.title} - SCORPIO MUSIC</title>
            <meta name="description" content={this.state.data.meta} />
          </Helmet>
        ) : null}
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          {!this.state.fetching ? (
            <CockpitComponentsDispatcher
              data={this.state.data}
              locale={this.props.match.params.locale}
            />
          ) : null}
        </Layout>
      </div>
    );
  }
}

export default PrivacyPolicy;
