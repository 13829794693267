import React, { Component } from "react";
import { SocialIcon } from "react-social-icons";

class SocialHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="socialheader">
        <a
          href="http://www.facebook.com/scorpiodigital"
          target="_blank"
          title="Facebook"
        >
          <img src="/assets/icons/facebook.svg" alt="Facebook" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCXaPWso3Q85gEzQ-DJ5lBoQ"
          target="_blank"
          title="Youtube"
        >
          <img src="/assets/icons/youtube_official.svg" alt="Youtube" />
        </a>
        <a
          href="https://twitter.com/scorpio_music"
          target="_blank"
          title="Twitter"
        >
          <img src="/assets/icons/twitter.svg" alt="Twitter" />
        </a>
        <a
          href="http://www.instagram.com/scorpiomusic"
          target="_blank"
          title="Instagram"
        >
          <img src="/assets/icons/insta.svg" alt="Instagram" />
        </a>
      </div>
    );
  }
}

// this.props.data.socials.map((item, i) => {
//   return (
//     <SocialIcon
//       key={i}
//       style={{ width: 32, height: 32, margin: "0 6px" }}
//       bgColor={this.props.color}
//       url={item.value}
//     />
//   );
// });

export default SocialHeader;
