import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import TagManager from "react-gtm-module";
import Icon from "@material-ui/core/Icon";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ShareIcon from "@material-ui/icons/Share";
import ClearIcon from "@material-ui/icons/Clear";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Button from "@material-ui/core/Button";
import ReleasesFeed from "../cockpitComponents/ReleasesFeed";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

import "./styles/release.css";
const ReactMarkdown = require("react-markdown/with-html");
class Release extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: [],
      dataRel: [],
      playerOpen: false,
      shareOpen: false
    };
  }

  togglePlayer = () => {
    //document.getElementById("spotifyIframe").classList.toggle("open");
    if (this.state.playerOpen) {
      this.setState({
        playerOpen: false
      });
    } else {
      this.setState({
        playerOpen: true
      });
    }
  };

  toggleShare = () => {
    //document.getElementById("spotifyIframe").classList.toggle("open");
    if (this.state.shareOpen) {
      this.setState({
        shareOpen: false
      });
    } else {
      this.setState({
        shareOpen: true
      });
    }
  };

  async fetchData() {
    // var locale = this.props.match.params.locale;
    // var filter;
    // if (locale === undefined) {
    //   filter = "title_slug";
    // } else {
    //   filter = `title_${locale}_slug`;
    // }

    const response = await fetch(
      `https://scorpiomusic.fr/cms/api/collections/get/releases`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "189f6f339371195fcccbd85236fdee"
        },
        body: JSON.stringify({
          //filter: { [filter]: this.props.match.params.id },
          filter: { url_handle: this.props.match.params.id },
          lang: this.props.match.params.locale
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData.entries[0]
    });
    console.log(jsonData);
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();

    this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      window.scroll(0, 0);
      await this.setState({
        fetching: true
      });

      await this.fetchData();

      this.setState({
        fetching: false
      });
    }
  }

  render() {
    var splittedDate;
    if (!this.state.fetching) {
      splittedDate = this.state.data.date.split("-");
    }

    if (!this.state.fetching && this.state.data.spotify_uri) {
      var splitUri = this.state.data.spotify_uri.split(":");
    }

    if (!this.state.fetching) {
      const tagManagerArgs = {
        dataLayer: {
          page: "release " + this.state.data.title
        },
        dataLayerName: "PageDataLayer"
      };
      TagManager.dataLayer(tagManagerArgs);
    }

    return (
      <div className="release">
        {!this.state.fetching ? (
          <Helmet>
            <html
              lang={
                this.props.match.params.locale
                  ? this.props.match.params.locale
                  : "en"
              }
            />
            <meta charSet="utf-8" />
            <meta
              property="og:title"
              content={`${this.state.data.artist} - ${
                this.state.data.title
              } - SCORPIO MUSIC`}
            />
            <meta
              property="og:image"
              content={`https://scorpiomusic.fr/cms/api/cockpit/image?token=189f6f339371195fcccbd85236fdee&src=${
                this.state.data.image._id
              }&w=1024&h=1024&q=80&o=true`}
            />

            <title>
              {this.state.data.artist} - {this.state.data.title} - SCORPIO MUSIC
            </title>
          </Helmet>
        ) : null}
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          {!this.state.fetching ? (
            <div>
              <div
                className="releaseInner"
                style={
                  this.state.data.image
                    ? {
                        background: `linear-gradient(to top,#fff,${this.state
                          .data.image.colors[0] + "90"})`
                      }
                    : null
                }
              >
                <div
                  className="topbg"
                  style={{
                    backgroundImage: `url(https://scorpiomusic.fr/cms/api/cockpit/image?token=189f6f339371195fcccbd85236fdee&src=${
                      this.state.data.image._id
                    }&w=1024&h=1024&q=80&o=true)`,
                    filter: "blur(20px)"
                  }}
                />
                <div className="maxwidth">
                  <div className="releaseImageContainer">
                    <img
                      className="releaseImage"
                      alt={this.state.data.title}
                      src={`https://scorpiomusic.fr/cms/api/cockpit/image?token=189f6f339371195fcccbd85236fdee&src=${
                        this.state.data.image._id
                      }&w=1024&h=1024&q=80&o=true`}
                    />

                    <div className="links">
                      {this.state.data.presave ? (
                        <a
                          className="link presave"
                          id="presave"
                          index="presave"
                          href={this.state.data.presave_url}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <span>
                            <strong>PRESAVE</strong>
                          </span>
                        </a>
                      ) : null}
                      {this.state.data.ext_player ? (
                        <a
                          className="link listen"
                          id="listen"
                          index="listen"
                          href={this.state.data.ext_player}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <strong>LISTEN</strong>
                          <PlayArrowIcon />
                        </a>
                      ) : null}
                      {this.state.data.links && !this.state.data.presave
                        ? this.state.data.links.map((link, i) => {
                            return (
                              <a
                                className="link"
                                index={i}
                                href={link.value}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {link.value.indexOf("deezer") != -1 ? (
                                  <img src="/assets/icons/deezer.svg" />
                                ) : link.value.indexOf("soundcloud") != -1 ? (
                                  <img src="/assets/icons/soundcloud.svg" />
                                ) : link.value.indexOf("spotify") != -1 ? (
                                  <img src="/assets/icons/spotify.svg" />
                                ) : link.value.indexOf("tidal") != -1 ? (
                                  <img src="/assets/icons/tidal.svg" />
                                ) : link.value.indexOf("play.google") != -1 ? (
                                  <img src="/assets/icons/google-play.svg" />
                                ) : link.value.indexOf("napster") != -1 ? (
                                  <img src="/assets/icons/napster.svg" />
                                ) : link.value.indexOf("itunes.apple") != -1 ? (
                                  <img src="/assets/icons/itunes.svg" />
                                ) : link.value.indexOf("music.apple") != -1 ? (
                                  <img src="/assets/icons/applemusic.svg" />
                                ) : link.value.indexOf("youtu") != -1 ? (
                                  <img src="/assets/icons/youtube.svg" />
                                ) : null}
                              </a>
                            );
                          })
                        : null}
                      <a
                        href="#"
                        id="share"
                        onClick={this.toggleShare}
                        className="link"
                      >
                        <strong>SHARE</strong> <ShareIcon />
                      </a>
                    </div>
                  </div>

                  <div className="releaseContentContainer">
                    <div className="releaseContent">
                      <div className="releaseContentData">
                        <div className="date">
                          {splittedDate[2]}-{splittedDate[1]}-{splittedDate[0]}
                        </div>

                        <div className="title">
                          <h1>{this.state.data.title}</h1>
                        </div>

                        <div className="artist">
                          <h2>{this.state.data.artist}</h2>
                        </div>

                        {this.state.data.yt ? (
                          <div
                            className="videos"
                            style={{ paddingBottom: "2rem" }}
                          >
                            {this.state.data.yt
                              ? this.state.data.yt.map((video, i) => {
                                  return (
                                    <div
                                      className="video"
                                      style={{
                                        marginTop: "1rem",
                                        position: "relative",
                                        paddingTop: "56.25%",
                                        height: "0"
                                      }}
                                    >
                                      <iframe
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          display: "block"
                                        }}
                                        width="100%"
                                        height="100%"
                                        src={`https://www.youtube.com/embed/${
                                          video.value.url
                                        }`}
                                        frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                      />
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        ) : null}
                      </div>

                      <ReactMarkdown
                        source={this.state.data.content}
                        escapeHtml={false}
                      />

                      {splitUri ? (
                        <div
                          id="spotifyIframe"
                          className={this.state.playerOpen ? "open" : ""}
                        >
                          {splitUri[1] == "album" ? (
                            <>
                              <Icon
                                onClick={this.togglePlayer}
                                className="toggler"
                                style={{
                                  display: "block",
                                  backgroundColor: "#ea244b",
                                  borderRadius: "4rem",
                                  color: "#fff",
                                  margin: "0 auto -0.6rem auto",
                                  position: "relative",
                                  zIndex: "2"
                                }}
                              >
                                {!this.state.playerOpen ? (
                                  <ExpandLessIcon id="expandless" />
                                ) : (
                                  <ExpandMoreIcon id="expandmore" />
                                )}
                              </Icon>

                              <iframe
                                style={{ display: "block" }}
                                src={`https://open.spotify.com/embed/album/${
                                  splitUri[2]
                                }`}
                                width="100%"
                                frameborder="0"
                                allowtransparency="true"
                                allow="encrypted-media"
                              />
                            </>
                          ) : (
                            <iframe
                              style={{ display: "block" }}
                              src={`https://open.spotify.com/embed/track/${
                                splitUri[2]
                              }`}
                              width="100%"
                              frameborder="0"
                              allowtransparency="true"
                              allow="encrypted-media"
                            />
                          )}
                        </div>
                      ) : null}
                      {this.state.shareOpen ? (
                        <div className="shareButtons">
                          {console.log(window.location.href)}
                          <div className="shareButtonsInner">
                            <div
                              style={{
                                textAlign: "left",
                                paddingBottom: "1rem",
                                lineHeight: "1.6rem"
                              }}
                            >
                              <strong>SHARE</strong>
                              <ClearIcon
                                style={{ cursor: "pointer", float: "right" }}
                                onClick={this.toggleShare}
                              />
                            </div>

                            <FacebookShareButton url={window.location.href}>
                              <FacebookIcon size={64} round={true} />
                            </FacebookShareButton>
                            <TwitterShareButton url={window.location.href}>
                              <TwitterIcon size={64} round={true} />
                            </TwitterShareButton>
                            <WhatsappShareButton url={window.location.href}>
                              <WhatsappIcon size={64} round={true} />
                            </WhatsappShareButton>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <ReleasesFeed limit={8} locale={this.props.match.params.locale} />

              <div style={{ textAlign: "center", paddingBottom: "4rem" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={
                    !this.props.match.params.locale
                      ? `/releases`
                      : `/${this.props.match.params.locale}/releases`
                  }
                >
                  SEE ALL
                </Button>
              </div>
            </div>
          ) : null}
        </Layout>
      </div>
    );
  }
}

export default Release;
