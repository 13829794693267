import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";

import CockpitSettingsWrapper from "./CockpitSettingsWrapper";

const ReactMarkdown = require("react-markdown/with-html");
class YoutubeVideo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var width = "1024px";
    var alignment = "left";
    if (this.props.data.settings.alignment === "left") {
      alignment = "left";
    }
    if (this.props.data.settings.alignment === "right") {
      alignment = "right";
    }
    if (this.props.data.settings.alignment === "center") {
      alignment = "center";
    }
    if (this.props.data.settings.alignment === "justify") {
      alignment = "justify";
    }
    if (this.props.data.settings.width === "large") {
      width = "1280px";
    }
    if (this.props.data.settings.width === "medium") {
      width = "1024px";
    }
    if (this.props.data.settings.width === "small") {
      width = "640px";
    }

    return (
      <div
        className="contentContainer"
        style={{
          backgroundColor: this.props.bgColor,
          position: "relative"
        }}
      >
        <CockpitSettingsWrapper data={this.props.data}>
          <iframe
            width="100%"
            height="315"
            src={this.props.data.settings.yt_url}
            title="video"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen={true}
            style={{
              height: "60vh",
              maxWidth: "1280px",
              display: "block",
              margin: "0 auto"
            }}
            frameBorder="0"
          />
        </CockpitSettingsWrapper>
      </div>
    );
  }
}
export default YoutubeVideo;
