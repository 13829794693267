import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";
import "./styles/info.css";
import CockpitSettingsWrapper from "./CockpitSettingsWrapper";

const ReactMarkdown = require("react-markdown/with-html");

class Info extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="infosContainer"
        style={{
          backgroundColor: this.props.bgColor,
          position: "realtive"
        }}
      >
        <CockpitSettingsWrapper data={this.props.data}>
          <Fade bottom cascade>
            <div className="infos">
              {this.props.data.settings.info.map((info, i) => {
                return (
                  <div className="info" key={i}>
                    <h2
                      style={{
                        color: this.props.titleColor
                      }}
                    >
                      {info.value.title}
                    </h2>
                    <p
                      style={{
                        color: this.props.textColor
                      }}
                    >
                      <ReactMarkdown
                        source={info.value.content}
                        escapeHtml={false}
                      />
                    </p>
                  </div>
                );
              })}
            </div>
          </Fade>
        </CockpitSettingsWrapper>
      </div>
    );
  }
}

export default Info;
